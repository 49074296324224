import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup,UntypedFormBuilder,Validators } from '@angular/forms';
import { HttpClient  } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import {MatDialogRef} from '@angular/material/dialog'
//import './contact-dialog.component.scss';
@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

    statusList=["APPROVED","PENDING"];
   contactForm!: UntypedFormGroup  
   ipAddress='';

  constructor( private http:HttpClient,private formBuilder : UntypedFormBuilder, private api : ApiService, private dialogRef: MatDialogRef<ContactDialogComponent>) { }

  ngOnInit(): void {
    this.contactForm=this.formBuilder.group({
      fname: ['',Validators.required],
      lname: ['',Validators.required],
      email: ['',Validators.required],
      description: ['',Validators.required],
      ip:[this.getIPAddress()]
    })
    
  }

  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
    console.log(this.ipAddress);
  }

  addComment(){
    this.getIPAddress();
    this.contactForm.value.ip=this.ipAddress;
    console.log("OBJECT:-->"+JSON.stringify(this.contactForm.value));
    if(this.contactForm.valid){
      this.api.postContact(this.contactForm.value)
      .subscribe({
        next:(res)=>{
          alert("Comment Added Successfully.")
          this.contactForm.reset();
          this.dialogRef.close();
        },
        error:()=>{
          alert("Error while adding the comment occurred");
        }
      })
    }
  }

}
