<nav style="width:52.5rem" class="navbar navbar-expand-lg navbar-dark bg-dark">

    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid">
                <a class="navbar-brand" href="#/home"><img style="width:4rem" src="/resource/assets/teknosys.gif" /><br /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/products?searchText=a">Products</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/privacy?en=us">Privacy US</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="/terms">Terms</a>
                        </li>
                         <li class="nav-item">
                            <a class="nav-link" href="/tactics/6">Tactics</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/sitemap">Sitemap</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/privacy?en=eu">Privacy</a>
                        </li>

                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Admin Functions
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/cms">CMS</a></li>
                                <li><a class="dropdown-item" href="/agenda">Agenda</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="/ip?pull=true">Update IPS</a></li>
                                <li class="dropdown-item">
                                    <a class="nav-link" href="/email">Email Marketing</a>
                                </li>
                                <li><a class="dropdown-item" href="/upload">uploads</a></li>
                                <li class="dropdown-item">
                                    <a class="nav-link" href="/report">Reports</a>
                                </li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Blog
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" href="/blog/main?url=adwords">Adwords concepts</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=autoblog">Auto blogging</a></li>
                                <li><hr class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="/blog/main?url=makemoney">Make Money Online</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=dofollow">dofollow links generation</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=organicsearch">Organic Search</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=overview">Overview</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=rankalgorithm">Rank Algorithm</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=affiliate">Affiliate Marketing</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=adsense">Adsense Concepts</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=keyword">Key Search Tool and generation</a></li>
                                <li><a class="dropdown-item" href="/blog/main?url=backlink">Backlink generation</a></li>
                                <li><a class="dropdown-item" href="/astrology">Planets Constellation</a></li>
                            </ul>
                        </li> -->
<!--                        <li class=" btn-primary nav-item text-white" role="button">
                            <div *ngIf="isUserLoggedIn(); then thenBlock else elseBlock"></div>
                            <ng-template #thenBlock><a class="nav-link text-white" (click)="logout()">logout</a>
                            </ng-template>
                            <ng-template #elseBlock> <a class="nav-link" routerLink="./login">login</a></ng-template>

                        </li>-->

                    </ul>
                    <form class="d-flex">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                             <li class="  nav-item text-white" role="button">
                            <div *ngIf="isUserLoggedIn(); then thenBlock else elseBlock"></div>
                            <ng-template #thenBlock><a class="nav-link text-white" (click)="logout()">logout</a>
                            </ng-template>
                            <ng-template #elseBlock> <a class="nav-link" routerLink="./login">login</a></ng-template>
                        </ul>
                        <!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">-->
                        <!--<button class="btn btn-outline-success" type="submit">Search</button>-->
                    </form>
                </div>
            </div>
        </nav>
    </div>
</nav>
