<footer id="dk-footer" class="dk-footer">
    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <span>Copyright © 2019, All Right Reserved Teknosys&reg;</span>
                </div>
            </div>
            <!-- End Row -->
        </div>
        <!-- End Copyright Container -->
    </div>
</footer>