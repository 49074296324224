import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { TekProductsTableComponent } from './tek-products-table/tek-products-table.component';
import { TekRegisterTableComponent } from './tek-register-table/tek-register-table.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { UploadComponent } from './components/upload/upload.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { EmailComponent } from './components/email/email.component';
import { ArticleComponent } from './components/article/article.component';
import { PanchangaComponent } from './panchanga/panchanga.component';


const routes: Routes = [
    {
        path: '',
        component: AdminDashboardComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'about', component: AboutComponent },
            { path: 'services', component: ServicesComponent },
            { path: 'products', component: TekProductsTableComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'login', component: LoginComponent },
            { path: 'forgot', component: ForgotPasswordComponent },
            { path: 'admin', component: AdminDashboardComponent },
            { path: 'terms', component: TermsComponent },
            { path: 'sitemap', component: SitemapComponent },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'register', component: TekRegisterTableComponent },
            { path: 'upload', component: UploadComponent },
            { path: 'email', component: EmailComponent },
            { path: 'article', component: ArticleComponent },
            { path: 'panchanga', component: PanchangaComponent },
            { path: '**', component: NotFoundComponent },
            { path: '#', redirectTo: '/home', pathMatch: 'full' },
        ],
    },
];
RouterModule.forRoot(routes, { useHash: true })

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
