import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import './not-found.component.scss';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

    ipAddress!: any;
    n1 = 0; n2 = 0;
    count = 0;
    arr = ['step0()', 'step1()', 'step2()', 'step3()', 'step4()', 'step5()', 'step6()', 'step7(n1,n2)', 'step8()', 'step9()', 'step10()', 'step11()'];


    constructor(
        private router: Router,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document, private http: HttpClient
    ) {
        this.getIPAddress();
        this.document = document;
    }


    ngOnInit(): void {
    }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
            localStorage.setItem('user', "GUEST-" + this.getIPAddress());
            return this.ipAddress;
        });
        //        console.log(this.ipAddress);
        return this.ipAddress;
    }

    //move files to respective date name.png
    //n=65; for i in $(ls  ~/Pictures);  do  j=`expr $j + 1`; echo $j;  d="`date -d "+${j} days" | tr ' ' '_'`"; echo $d; echo "$i"; mv $i $d.png;  done;

    step11() {
        this.n2++;
        if (this.n2 >= 7) {
            this.n1++;
            this.n2 = 0;
        }
        console.log(this.n1 + ":" + this.n2)
    };
    step10() {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Schedule']").forEach(e => e.click());
        console.log("step schedule called " + new Date());

    };

    step9() {
        this.document.querySelectorAll<HTMLElement>("div[aria-checked='false'][role=option").forEach(e => e.click());
        console.log("step time selected called " + new Date());
    };
    step8() {

        this.document.querySelectorAll<HTMLElement>("label[aria-label='Open Time Picker']").forEach(e => e.click());


        console.log("step time picker called " + new Date());

    };
    step7(n1: number, n2: number) {
        // this.document.querySelectorAll<HTMLElement>("div[aria-current='date']").forEach(e => e.click());
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Choose Date']").forEach(e => e.querySelectorAll<HTMLElement>("div[role='row']")[n1].querySelectorAll<HTMLElement>("div[aria-current=date]")[n2].click());
        console.log("step choose date called " + new Date());
        n2++;
        if (n2 >= 7) {
            n1++;
            n2 = 0;
        }

    };
    step6() {
        this.document.querySelectorAll<HTMLElement>("label[aria-label='Open Date Picker']").forEach(e => e.click());
        //next month
        //skip march
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Next Month']").forEach(e => e.click());
        //skip april
        window.setTimeout(() => {
            console.log("3 seconds after Next month called as well we we need to skip March and April months tweak logic accordingly");
            this.document.querySelectorAll<HTMLElement>("div[aria-label='Choose Date']").forEach(e => {
                console.log("Last Date:-->" + e.querySelectorAll<HTMLElement>("div[role='row']")[this.n1].querySelectorAll<HTMLElement>("div[aria-current=date]")[this.n2 - 1].textContent);
            })
            this.document.querySelectorAll<HTMLElement>("div[aria-label='Next Month']").forEach(e => e.click());
        }, 3000);
        console.log("step date picker LABEL called " + new Date());

    };
    step5() {
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Open Date Picker']").forEach(e => e.click());
        console.log("step date picker DIV called " + new Date());

    };
    step4() {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Schedule post']").forEach(e => e.click());
        console.log("step Schedule post called " + new Date());

    };
    step3() {

        this.document.querySelectorAll<HTMLElement>("div[role=button]").forEach(e => {
            if (e.previousSibling instanceof HTMLInputElement && e.previousSibling.accept.indexOf("mkv") !== -1) {
                console.log(e.previousSibling.accept);
                e.previousSibling.click();
            }
        });
        console.log("step file selected called " + new Date());

    };
    step2() {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Photo/video']").forEach(e => e.click());
        console.log("step photo/video called " + new Date());

    };
    step1() {

        this.document.querySelectorAll<HTMLElement>("span").forEach(e => {
            if (e.textContent === 'Photo/video') {
                e.click();
            }
        });
        console.log("step create post called " + new Date());

    };

    exec() {
        console.log(this.arr);
        console.log(this.count);
        switch (this.arr[this.count]) {
            case "step1()":
                this.step1(); break;
            case "step2()":
                this.step2(); break;
            case "step3()":
                this.step3(); break;
            case "step4()":
                this.step4(); break;
            case "step5()":
                this.step5(); break;
            case "step6()":
                this.step6(); break;
            case "step7(n1,n2)":
                this.step7(this.n1, this.n2); break;
            case "step8()":
                this.step8(); break;
            case "step9()":
                this.step9(); break;
            case "step10()":
                this.step10(); break;
            case "step11()":
                this.step11(); break;
        }
        this.count++;
        if (this.count >= 12) {
            this.count = 1;
        }
        console.log("COUNT:-->" + this.count);
    };

}