import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import './article-view-dialog.component.scss';

@Component({
    selector: 'app-article-view-dialog',
    templateUrl: './article-view-dialog.component.html',
    styleUrls: ['./article-view-dialog.component.scss']
})
export class ArticleViewDialogComponent implements OnInit, AfterViewInit {


    displayedColumns: string[] = ['content', 'comments', 'url', 'block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;
    dialog!: any;
    slideHtml!: string[];

    setSlideHtml(html: string[]) {
        this.slideHtml = html;
        console.log(html);
    }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    constructor(private api: ApiService, dialog: MatDialog) {
        // Create 100 users
        this.dialog = dialog;
        this.getArticle();
        // Assign the data to the data source for the table to render

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    ngOnInit() {
    }
    ngAfterViewInit() {

    }


    getArticle() {

        if (true) { //form valid need to check
            //this.productForm.value
            let url = localStorage.getItem('selected-article') || "a";
            console.log("Dialog Article selected:-->" + url);
            return this.api.getArticle().subscribe({
                next: (res) => {
                    console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.setSlideHtml(res);
                },
                error: () => {
                    alert("error while retrieving the Article");
                }
            })
        }
    }


}
