<div class="container pt-5 mt-5">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">

                <h2>
                    Terms and Conditions</h2>
                <div class="error-details">
                    <div id="content">
                       <pre></pre>
                        <p></p>

                        <p>&nbsp;</p>

                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Terms and Conditions of Teknosys</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Below are the Terms and Conditions for use of https://www.teknosys.in/.
                            Please read these carefully. If you need to contact us regarding any aspect
                            of the following terms of use of our website, please contact us at
                            admin@teknosys.in</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            By accessing the content of Teknosys you agree to the terms and conditions
                            set out herein and also accept our privacy policy. If you do not agree to
                            any of the terms and conditions you should not continue to use the website
                            and leave immediately.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            You agree that you shall not use the website for any illegal purposes and
                            that you will respect all applicable laws and regulations.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            You agree not to use Teknosyswebsite in a way that may impair the
                            performance, corrupt or manipulate the content or information available on
                            the website or reduce the overall functionality of the website.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            You agree not to compromise the security of the website or attempt to gain
                            access to secured areas of the website or attempt to access any sensitive
                            information you may believe exist on the website or server where it is
                            hosted.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            You agree to be fully responsible for any claim, expense, losses, liability,
                            costs including legal fees incurred by us arising from any infringement of
                            the terms and conditions in this agreement and to which you will have agreed
                            if you continue to use the website.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            The reproduction, distribution in any method whether online or offline is
                            strictly not prohibited. The work on the website and the images, logos, text
                            and other such information are the property of https://www.teknosys.in/ (
                            unless otherwise stated ).</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Disclaimer</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Though we strive to be completely accurate in the information that is
                            presented on our site and attempt to keep it as up to date as possible, in
                            some cases, some of the information you find on the website may be slightly
                            outdated.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Teknosys reserves the right to make any modifications or corrections to the
                            information you find on the website at any time without notice.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Change to the Terms and Conditions of Use</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px 0px 1.6em; outline: 0px; padding: 0px; vertical-align: baseline;">
                            We reserve the right to make changes and to revise the above-mentioned Terms
                            and Conditions of use.</p>
                        <p style="border: 0px; box-sizing: inherit; font-style: inherit; font-weight: inherit; margin: 0px; outline: 0px; padding: 0px; vertical-align: baseline;">
                            Last Revised:&nbsp;Wed Sep 14 21:54:33 CDT 2022
                        </p>


                    </div>
                </div>
                <div class="error-actions">
                    <a href="/home" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Take Me Home </a><a href="/contact" class="btn btn-default btn-lg"><span class="glyphicon glyphicon-envelope"></span> Contact Support </a>
                </div>
            </div>
        </div>
    </div>
</div>