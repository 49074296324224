import { DOCUMENT } from '@angular/common';
import { Component, ViewChild, AfterViewInit, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JQUERY_TOKEN } from './common/jquery.component';
//import './app.component.scss';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'tek-angular';
    ipAddress!: any;
    n1!: number; n2!: number;
    count = 0;
    arr!: ['step0()', 'step1()', 'step2()', 'step3()', 'step4()', 'step5()', 'step6()', 'step7(n1,n2)', 'step8()', 'step9()', 'step10()', 'step11()'];
    called = false;

 public $: any;
 public ngOnInit(){
  this.$=this.$factory();
 }
    ngAfterViewInit() {
    }

    constructor(
        private router: Router,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document, private http: HttpClient,
         @Inject(JQUERY_TOKEN) private $factory: any
    ) {
        this.handleRouteEvents();
        this.document = document;
        console.log(this.document.location.href);
        if (this.document.location.href.indexOf("/register") != -1) {
            this.router.navigate(['/register']);
        } else if (this.document.location.href.indexOf("/contact") != -1) {
            this.router.navigate(['/contact']);
        }
        else if (this.document.location.href.indexOf("/reset") != -1) {
            this.router.navigate(['/forgot']);
        }
        else if (this.document.location.href.indexOf("/forgot") != -1) {
            this.router.navigate(['/forgot']);
        }
        else if (this.document.location.href.indexOf("/article") != -1) {
            this.router.navigate(['/article']);
        }
        else if (this.document.location.href.indexOf("/privacy") != -1) {
            this.router.navigate(['/privacy']);
        }
        else if (this.document.location.href.indexOf("/sitemap") != -1) {
            this.router.navigate(['/sitemap']);
        } else {
            this.router.navigate(['/home']);
        }
    }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
            localStorage.setItem('user', "GUEST-" + this.getIPAddress());
            this.called = true;
            return this.ipAddress;
        });
        console.log(this.ipAddress + ":" + this.called);
        return this.ipAddress;
    }

    //move files to respective date name.png
    //n=65; for i in $(ls  ~/Pictures);  do  j=`expr $j + 1`; echo $j;  d="`date -d "+${j} days" | tr ' ' '_'`"; echo $d; echo "$i"; mv $i $d.png;  done;

    step11 = () => {
        this.n2++;
        if (this.n2 >= 7) {
            this.n1++;
            this.n2 = 0;
        }
        console.log(this.n1 + ":" + this.n2)
    };
    step10 = () => {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Schedule']").forEach(e => e.click());
        console.log("step schedule called " + new Date());

    };

    step9 = () => {
        this.document.querySelectorAll<HTMLElement>("div[aria-checked='false'][role=option").forEach(e => e.click());
        console.log("step time selected called " + new Date());
    };
    step8 = () => {

        this.document.querySelectorAll<HTMLElement>("label[aria-label='Open Time Picker']").forEach(e => e.click());


        console.log("step time picker called " + new Date());

    };
    step7 = (n1: number, n2: number) => {
        // this.document.querySelectorAll<HTMLElement>("div[aria-current='date']").forEach(e => e.click());
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Choose Date']").forEach(e => e.querySelectorAll<HTMLElement>("div[role='row']")[n1].querySelectorAll<HTMLElement>("div[aria-current=date]")[n2].click());
        console.log("step choose date called " + new Date());
        n2++;
        if (n2 >= 7) {
            n1++;
            n2 = 0;
        }

    };
    step6 = () => {
        this.document.querySelectorAll<HTMLElement>("label[aria-label='Open Date Picker']").forEach(e => e.click());
        //next month
        //skip march
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Next Month']").forEach(e => e.click());
        //skip april
        window.setTimeout(() => {
            console.log("3 seconds after Next month called as well we we need to skip March and April months tweak logic accordingly");
            this.document.querySelectorAll<HTMLElement>("div[aria-label='Choose Date']").forEach(e => {
                console.log("Last Date:-->" + e.querySelectorAll<HTMLElement>("div[role='row']")[this.n1].querySelectorAll<HTMLElement>("div[aria-current=date]")[this.n2 - 1].textContent);
            })
            this.document.querySelectorAll<HTMLElement>("div[aria-label='Next Month']").forEach(e => e.click());
        }, 3000);
        console.log("step date picker LABEL called " + new Date());

    };
    step5 = () => {
        this.document.querySelectorAll<HTMLElement>("div[aria-label='Open Date Picker']").forEach(e => e.click());
        console.log("step date picker DIV called " + new Date());

    };
    step4 = () => {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Schedule post']").forEach(e => e.click());
        console.log("step Schedule post called " + new Date());

    };
    step3 = () => {

        this.document.querySelectorAll<HTMLElement>("div[role=button]").forEach(e => {
            if (e.previousSibling instanceof HTMLInputElement && e.previousSibling.accept.indexOf("mkv") !== -1) {
                console.log(e.previousSibling.accept);
                e.previousSibling.click();
            }
        });
        console.log("step file selected called " + new Date());

    };
    step2 = () => {

        this.document.querySelectorAll<HTMLElement>("div[aria-label='Photo/video']").forEach(e => e.click());
        console.log("step photo/video called " + new Date());

    };
    step1 = () => {

        this.document.querySelectorAll<HTMLElement>("span").forEach(e => {
            if (e.textContent === 'Photo/video') {
                e.click();
            }
        });
        console.log("step create post called " + new Date());

    };

    exec = () => {
        eval(this.arr[this.count]);
        this.count++;
        if (this.count >= 12) {
            this.count = 1;
        }
        console.log("COUNT:-->" + this.count);
    };


    setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    handleRouteEvents() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
                this.titleService.setTitle(title);
                console.log("page_title:-->" + title);
                console.log("page_path:-->" + event.urlAfterRedirects);
                console.log("page_location:-->" + this.document.location.href);
                gtag('event', 'page_view', {
                    page_title: title,
                    page_path: event.urlAfterRedirects,
                    page_location: this.document.location.href
                })
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }
}
