import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleDialogComponent } from '../../article-dialog/article-dialog.component';
import { ArticleViewDialogComponent } from '../../article-view-dialog/article-view-dialog.component';
//import './article.component.scss';


@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements AfterViewInit {


    displayedColumns: string[] = ['content', 'comments', 'url', 'block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;
    dialog!: any;
    viewDialog!: any;
    slideHtml!: string[];

    setSlideHtml(html: string[]) {
        this.slideHtml = html;
        console.log(html);
    }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    constructor(private api: ApiService, dialog: MatDialog, viewDialog: MatDialog) {
        // Create 100 users
        this.dialog = dialog;
        this.viewDialog = viewDialog;

        // Assign the data to the data source for the table to render

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openDialog() {
        this.dialog.open(ArticleDialogComponent, {
            width: "100%",
            height: "50%"
        });
    }
    openViewDialog(event: Event) {
        let url = (event.target as HTMLButtonElement).textContent || "";
        console.log("Article selected:-->" + url);
        localStorage.setItem('selected-article', url);
        this.viewDialog.open(ArticleViewDialogComponent, {
            width: "100%",
            height: "50%"
        });
    }

    postArticle() {
        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.postArticle({
                "content": "",
                "comments": "",
                "url": "",
            }).subscribe({
                next: (res) => {
                    alert("Article added successfully" + res);
                },
                error: () => {
                    alert("error while adding the Article");
                }
            })
        }
    }
    getToken(): string {
        return localStorage.getItem('token') || 'abc@gmail.com';
    }

    getArticle() {

        if (true) { //form valid need to check
            //this.productForm.value

            return this.api.getArticle().subscribe({
                next: (res) => {
                    console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.setSlideHtml(res);
                },
                error: () => {
                    alert("error while retrieving the Article");
                }
            })
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }

}
