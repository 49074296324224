<h1 mat-dialog-title> Add Comment</h1>
<div mat-dialog-content>
   <form [formGroup]="contactForm">
    <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input formControlName="fname" matInput placeholder="First Name">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input formControlName="lname" matInput placeholder="Last Name">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder="Email">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
      

      

      <mat-form-field appearance="outline">
        <mat-label>Comment</mat-label>
        <input formControlName="description" matInput placeholder="Comment">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      
   </form>
      
</div>
<div mat-dialog-actions [align]="'end'">
<button mat-raised-button color="warn" mat-dialog-close> Close</button>
<button style="margin-left: 8px;" mat-raised-button color="primary" (click)="addComment()"> Save</button>
</div>