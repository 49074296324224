<mat-label [style]="dialog"></mat-label>
<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. affiliate" #input>
</mat-form-field>

<div class="mat-elevation-z8 dk-footer" >
    <table class="dk-footer" mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> S.no </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
            <td mat-cell *matCellDef="let row"> {{row.category}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Image </th>
            <td mat-cell *matCellDef="let row"> <img src={{row.image}} /></td>
        </ng-container>

        <!-- Fruit Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> URL </th>
            <td mat-cell *matCellDef="let row"> <a href='{{row.url}}'>{{row.name}}</a> </td>
        </ng-container>
        <ng-container matColumnDef="reviewCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Review Count </th>
            <td mat-cell *matCellDef="let row"> {{row.reviewCount}} </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
            <td mat-cell *matCellDef="let row"> {{row.startDate}} </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
            <td mat-cell *matCellDef="let row"> {{row.endDate}} </td>
        </ng-container>
        <ng-container matColumnDef="display">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
            <td mat-cell *matCellDef="let row"> {{row.endDate}} </td>
        </ng-container>

        <ng-container matColumnDef="approved">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Approved </th>
            <td mat-cell *matCellDef="let row"> {{row.approved}} </td>
        </ng-container>

        <ng-container matColumnDef="block">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> View </th>
            <td mat-cell *matCellDef="let row"> <button mat-raised-button (click)="openViewDialog($event)" color="primary">{{row.name}}</button> </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of contacts"></mat-paginator>


    <button mat-raised-button style="margin: 10px;" (click)="openDialog()" color="primary">ADD</button>
    <button mat-raised-button (click)="getProduct()" color="primary">RETRIEVE</button>
    <!-- <button mat-raised-button color="warn">Warn</button> -->


</div>