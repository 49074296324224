import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup,UntypedFormBuilder,Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import {MatDialogRef} from '@angular/material/dialog'
//import './email-dialog.component.scss';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {


  statusList=["disable","enable"];
   emailForm!: UntypedFormGroup  

  constructor( private formBuilder : UntypedFormBuilder, private api : ApiService, private dialogRef: MatDialogRef<EmailDialogComponent>) { }

  ngOnInit(): void {
    this.emailForm=this.formBuilder.group({
      email: ['',Validators.required],
      disable: ['',Validators.required],
    })
  }

  addEmail(){
    console.log(this.emailForm.value);
    if(this.emailForm.valid){
      this.api.postEmail(this.emailForm.value)
      .subscribe({
        next:(res)=>{
          alert("Email Added Successfully.")
          this.emailForm.reset();
          this.dialogRef.close();
        },
        error:()=>{
          alert("Error while adding the Email occurred");
        }
      })
    }
  }

}
