<div class="container pt-5 pb-5">
    <div class="col-12">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Affiliate Introduction Component</h5>
                    <p class="card-text">Welcome to Teknosys!. this site is all about affiliate marketing and its basic concepts . 
                        feel free to <a href='/login'>Login</a> or <a href='register'>register</a> here to scan your website and optimize it . You will get opportunity to get Back links Generator,auto blogger ,page rank analyzer and Blog buddy . send us email Admin.
                        Welcome to Teknosys Affiliate Marketing Research
                        You might be wondered  How do I make money online and generate passive income . Yes you can make money online there are wonderful techniques and ideas which we wanted to share here . we have team of 5 freelancing web developers united together and created concrete implementation of Money Making machines . all you just need Laptop and internet . No need to become Tech savvy to generate passive income . say Good bye to 9-5 work and sales deadlines . you can work from any where in the world and create content let it generate income for you . here look at our Adsense </p>
                    <a href='/register' class="btn btn-primary">Register</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Tools : Auto Blogging Buddy</h5>
                    <p class="card-text">We have developed industry leading software for Auto blogging.the key factor for generating more organic traffic is based on Keywords and content generation.
                        most of the google bots and crwlers scan the keywords frequency in your content and decide page ranking based on that. so you need to have quality content and keywords with high frequency.
                        in the next sesion you will be understandin our another tool <a href='/keyword'>keyword crawler</a> which helps you to get frequency of keywords and generate report
                    </p>
                    <a href='https://www.youtube.com/channel/UC7ibx4i86f6fSBpx0jVrrPw' class="btn btn-primary">Youtube Channel</a>
                </div>
                <div class="accordion" id="teknosys-accordion-4873443734">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                    aria-controls="panelsStayOpen-collapseOne">
                                Teknosys Affiliate Marketing
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                             aria-labelledby="panelsStayOpen-headingOne">
                            <div class="accordion-body">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Li8iGi_W-JE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Admin Component</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a class="btn btn-primary">Go somewhere</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Admin Component</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a class="btn btn-primary">Go somewhere</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Admin Component</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a class="btn btn-primary">Go somewhere</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Admin Component</h5>
                    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <a class="btn btn-primary">Go somewhere</a>
                </div>
            </div>
        </div>
    </div>
</div>