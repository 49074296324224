<h1 mat-dialog-title> Add Article</h1>
<div mat-dialog-content>
   <form [formGroup]="articleForm">
    <mat-form-field appearance="outline">
        <mat-label>Content</mat-label>
        <input formControlName="content" matInput placeholder="content">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
       
       <mat-form-field appearance="outline">
        <mat-label>Comments</mat-label>
        <input formControlName="comments" matInput placeholder="comments">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
       
       <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <input formControlName="url" matInput placeholder="url">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      

      
      <label><h1>Content Status</h1></label>
      <mat-radio-group formControlName="disable" class="example-radio-group">
          <mat-radio-button class="example-radio-button" *ngFor="let status of statusList" [value]="status">
              {{status}}
          </mat-radio-button>
      </mat-radio-group>
   </form>
      
</div>
<div mat-dialog-actions [align]="'end'">
<button mat-raised-button color="warn" mat-dialog-close> Close</button>
<button style="margin-left: 8px;" mat-raised-button color="primary" (click)="addArticle()"> Save</button>
</div>