<h1 mat-dialog-title> Add Product</h1>
<div mat-dialog-content>
   <form [formGroup]="productForm">
    <mat-form-field appearance="outline">
        <mat-label>Product Name</mat-label>
        <input formControlName="name" matInput placeholder="Product Name">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Image</mat-label>
        <input formControlName="image" matInput placeholder="Product Image">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Choose a start date</mat-label>
        <input formControlName="startDate"  matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Choose a end date</mat-label>
        <input formControlName="endDate" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <input formControlName="url" matInput placeholder="Product URL">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Review</mat-label>
        <input formControlName="reviewCount" matInput placeholder="Reviews">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
         <mat-select formControlName="category" placeholder="Product Categories">
             <mat-option value="SOFTWARE">SOFTWARE</mat-option>
             <mat-option value="BOOKS">BOOKS</mat-option>
         </mat-select>
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
      <label><h1>Product Status</h1></label>
      <mat-radio-group formControlName="approved" class="example-radio-group">
          <mat-radio-button class="example-radio-button" *ngFor="let status of statusList" [value]="status">
              {{status}}
          </mat-radio-button>
      </mat-radio-group>
   </form>
      
</div>
<div mat-dialog-actions [align]="'end'">
<button mat-raised-button color="warn" mat-dialog-close> Close</button>
<button style="margin-left: 8px;" mat-raised-button color="primary" (click)="addProduct()"> Save</button>
</div>