import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import './ipdialog.component.scss';

@Component({
    selector: 'app-ipdialog',
    templateUrl: './ipdialog.component.html',
    styleUrls: ['./ipdialog.component.scss']
})
export class IpdialogComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['hostname', 'asn', 'isp', 'continent', 'country', 'state', 'city', 'time', 'ip', 'block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    constructor(private api: ApiService, dialog: MatDialog) {
        // Create 100 users
        // Assign the data to the data source for the table to render
        this.getIP();
    }

    ngOnInit() {
    }

    getIP() {

        if (true) { //form valid need to check
            //this.productForm.value
            let ip = localStorage.getItem("selected") || "";
            console.log("Dialog IP :-->" + ip);
            return this.api.getIPInfo(ip).subscribe({
                next: (res) => {
                    // console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                },
                error: () => {
                    alert("error while retrieving the IPS");
                }
            })
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }
}
