<div class="card dk-footer shadow mt-2">
    <div class="card-body">
        <h5 class="card-title">sitemaps can robots follow</h5>
        <p class="card-text">
            This site can be accssed through below sitemap urls
        </p>
        <ul>
            <li><a href='https://www.teknosys.in/#/home'>https://www.teknosys.in/#/home</a></li>
            <li><a href='https://www.teknosys.in/#/contact'>https://www.teknosys.in/#/contact</a></li>
            <li><a href='https://www.teknosys.in/#/terms'>https://www.teknosys.in/#/terms</a></li>
            <li><a href='https://www.teknosys.in/#/login'>https://www.teknosys.in/#/login</a></li>
            <li><a href='https://www.teknosys.in/#/forgot'>https://www.teknosys.in/#/forgot</a></li>
            <li><a href='https://www.teknosys.in/#/privacy'>https://www.teknosys.in/#/privacy</a></li>
            <li><a href='https://www.teknosys.in/#/register'>https://www.teknosys.in/#/register</a></li>
            <li><a href='https://www.teknosys.in/#/upload'>https://www.teknosys.in/#/upload</a></li>
            <li><a href='https://www.teknosys.in/#/about'>https://www.teknosys.in/#/about</a></li>
            <li><a href='https://www.teknosys.in/#/products'>https://www.teknosys.in/#/products</a></li>
            <li><a href='https://www.teknosys.in/#/products'>https://www.teknosys.in/#/email</a></li>
            <li><a href='https://www.teknosys.in/#/article'>https://www.teknosys.in/#/article</a></li>

        </ul>
    </div>
</div>