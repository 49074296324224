<mat-label [style]="dialog">Visited IPs list</mat-label><br/>
<mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
</mat-form-field>

<div class="mat-elevation-z8 dk-footer">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="hostname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hostname </th>
      <td mat-cell *matCellDef="let row"> {{row.hostname}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="asn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ASN </th>
      <td mat-cell *matCellDef="let row"> {{row.asn}}% </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="isp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ISP </th>
      <td mat-cell *matCellDef="let row"> {{row.isp}} </td>
    </ng-container>

    <!-- Fruit Column -->
    <ng-container matColumnDef="continent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> continent </th>
      <td mat-cell *matCellDef="let row"> {{row.continent}} </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> country </th>
      <td mat-cell *matCellDef="let row"> {{row.country}} </td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> state </th>
      <td mat-cell *matCellDef="let row"> {{row.state}} </td>
    </ng-container>
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> city </th>
      <td mat-cell *matCellDef="let row"> {{row.city}} </td>
    </ng-container>
    <ng-container matColumnDef="longitude">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> longitude </th>
      <td mat-cell *matCellDef="let row"> {{row.longitude}} </td>
    </ng-container>
    <ng-container matColumnDef="latitude">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> latitude </th>
      <td mat-cell *matCellDef="let row"> {{row.latitude}} </td>
    </ng-container>
    <ng-container matColumnDef="postalcode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> postalcode </th>
      <td mat-cell *matCellDef="let row"> {{row.postalcode}} </td>
    </ng-container>
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> time </th>
      <td mat-cell *matCellDef="let row"> {{row.time}} </td>
    </ng-container>
    <ng-container matColumnDef="ip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ip </th>
      <td #divIP mat-cell *matCellDef="let row"> {{row.ip}} </td>
    </ng-container>
    <ng-container matColumnDef="block">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> View </th>
      <td mat-cell *matCellDef="let row"> <button mat-raised-button (click)="openDialog($event)" color="primary">{{row.ip}}</button> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  
  
  <button mat-raised-button (click)="getIPS()" color="primary">RETRIEVE</button>
  <!-- <button mat-raised-button color="warn">Warn</button> -->

 
</div>