import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ProductViewDialogComponent } from '../product-view-dialog/product-view-dialog.component';
//import './tek-products-table.component.scss';

@Component({
    selector: 'app-tek-products-table',
    templateUrl: './tek-products-table.component.html',
    styleUrls: ['./tek-products-table.component.scss']
})

export class TekProductsTableComponent implements AfterViewInit {

    displayedColumns: string[] = ['id', 'category', 'endDate', 'image', 'name', 'reviewCount', 'startDate', 'url', 'approved','block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;
    dialog!: any;
    viewDialog!: any;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    constructor(private api: ApiService, dialog: MatDialog, viewDialog: MatDialog) {
        // Create 100 users
        this.dialog = dialog;
        this.viewDialog = viewDialog;

        // Assign the data to the data source for the table to render

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openDialog() {
        this.dialog.open(DialogComponent, {
            width: "100%",
            height: "50%"
        });
    }

    openViewDialog(event: Event) {
        let product = (event.target as HTMLButtonElement).textContent || "";
        console.log("Product selected:-->" + product);
        localStorage.setItem('selected-product', product);
        this.viewDialog.open(ProductViewDialogComponent, {
            width: "100%",
            height: "50%"
        });
    }

    postProduct() {
        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.postProduct({
                "id": "",
                "category": "",
                "end_date": "",
                "image": "/teknosys.png",
                "name": "Affiliate Product",
                "review_count": "1",
                "start_date": "10/10/2022",
                "url": "/get/affiliate",
                "approved": "approved"
            }).subscribe({
                next: (res) => {
                    alert("Product added successfully" + res);
                },
                error: () => {
                    alert("error while adding the product");
                }
            })
        }
    }

    getProduct() {

        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.getProduct().subscribe({
                next: (res) => {
                    // console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                },
                error: () => {
                    alert("error while retrieving the Products");
                }
            })
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }
}

