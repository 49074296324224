<div class="container" appDnd (fileDropped)="onFileDropped($event)">
      <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
       
<!--      <button (click)="onUpload()" 
        class="btn btn-success">
        Upload
    </button>-->
  <img src="assets/icon-upload.svg" alt="">
  <h3>Drag and drop file here</h3>
  <h3>or</h3>
  <label for="fileDropRef">Browse for file</label>
  
</div>
<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <img src="/resource/assets/icon-save.svg" width="45px" alt="file">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <!--<app-progress [progress]="file?.progress"></app-progress>-->
    </div>
    <img src="/resource/assets/icon-delete.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
  </div>
</div>