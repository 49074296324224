<div class="container vh-100">
    <div class="row justify-content-center h-100">
        <div class="card my-auto shadow">
            <div class="card-body px-5">
                <div class="text-center">
                    <fa-icon [icon]="faLock" class="fa-3x"></fa-icon>
                </div>
                <div class="text-center">
                    <h2>Registration Form!</h2>
                </div>
                <p style="color: lightslategray; text-align: center;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please register
                    details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <input type="email" id="email" placeholder="Email" class="form-control" formControlName="email"
                               name="email" />
                    </div>
                    <div class="form-group">
                        <input type="text" id="fname" placeholder="First Name" class="form-control" formControlName="fname"
                               name="email" />
                    </div>
                    <div class="form-group">
                        <input type="text" id="lname" placeholder="Last Name" class="form-control" formControlName="lname"
                               name="email" />
                    </div>
                    <div class="form-group">
                        <input type="email" id="username" placeholder="User Name" class="form-control" formControlName="username"
                               name="email" />
                    </div>
                    <div class="form-group">
                        <input type="password" id="password" placeholder="Password" class="form-control"
                               formControlName="password" name="password" />
                    </div>
                    <div class="form-group">
                        <input type="password" id="repassword" placeholder="Re type Password" class="form-control"
                               formControlName="repassword" name="repassword" />
                    </div>
                    <input type="submit" class="btn btn-primary w-100" value="Submit" name="">
                </form>
            </div>
            <div class="card-footer text-center">
                <small><i>Already Member &nbsp;</i> <a routerLink="/login">Login Here</a></small>
            </div>
            
        </div>
    </div>
</div>