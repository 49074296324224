import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IpdialogComponent } from '../ipdialog/ipdialog.component';
////import './tek-table.component.scss';


@Component({
    selector: 'app-tek-table',
    templateUrl: './tek-table.component.html',
    styleUrls: ['./tek-table.component.scss']
})
export class TekTableComponent implements AfterViewInit {

    displayedColumns: string[] = ['hostname', 'asn', 'isp', 'continent', 'country', 'state', 'city', 'longitude', 'latitude', 'postalcode', 'time', 'block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;
    dialog!: any;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    constructor(private api: ApiService, dialog: MatDialog) {
        // Create 100 users
        this.dialog = dialog;
        this.getIPS();
        // Assign the data to the data source for the table to render

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    openDialog(event: Event) {
        let ip = (event.target as HTMLButtonElement).textContent||"";
        console.log("IP selected:-->" + ip);
        localStorage.setItem('selected', ip);
        this.dialog.open(IpdialogComponent, {
            width: "100%"
        });
    }

    postIPS() {
        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.postIPS({
                "hostname": "",
                "asn": "",
                "isp": "",
                "continent": "North America",
                "country": "United States",
                "state": "California",
                "city": "Mountain View",
                "longitude": "-122.075409",
                "latitude": "37.419159",
                "postalcode": "94043",
                "time": "2020-05-16 16:32:56.0",
                "ip": "66.249.70.1",
                "val": ""
            }).subscribe({
                next: (res) => {
                    alert("IP added successfully" + res);
                },
                error: () => {
                    alert("error while adding the product");
                }
            })
        }
    }

    getIPS() {

        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.getIPS().subscribe({
                next: (res) => {
                    // console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                },
                error: () => {
                    alert("error while retrieving the IPS");
                }
            })
        }
    }



    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }
}


