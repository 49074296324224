<div class="container pt-5 pb-5">
    <div class="col-12">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Auto Blogging </h5>
                    <p class="card-text">We are expertised in generating organic traffic through Auto blogging tool.
                        this tool enables all registered users to upload their content
                        and keep it schedule. as you know organic traffic generates only through rich content. for a
                        instance you need to generate content with below high traffic keywords
                        which used frquently in the content allows search engines to crwal your pages and index them.
                        when users searches for specific keyword , your website pages will be displayed
                        in the order of ranking . high frequent keywords searched in 2022.
                    </p>
                    <ul>
                        <li>Accounting Keywords</li>
                        <li>Affiliate Keywords</li>
                        <li>Auto Insurance Keywords</li>
                        <li>B2B Ecommerce Keywords</li>
                        <li>B2B Keywords</li>
                        <li>Biotechnology Keywords</li>
                        <li>Business Keywords</li>
                        <li>Business Management Keywords</li>
                        <li>Business Travel Keywords</li>
                        <li>Construction Keywords</li>
                        <li>Credit Card Keywords</li>
                        <li>Debt Keywords</li>
                        <li>Entertainment Keywords</li>
                        <li>Finance Keywords</li>
                        <li>Human Resources Keywords</li>
                        <li>Insurance Business Keywords</li>
                        <li>Internet Security Keywords</li>
                        <li>Management Keywords</li>
                        <li>Office Supplies Keywords</li>
                        <li>Online Payment Solutions Keywords</li>
                        <li>Software Keywords</li>
                        <li>Startup Keywords</li>
                        <li>Web Hosting Keywords</li>
                    </ul>

                    <a class="btn btn-primary">BUSINESS KEYWORDS</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Health & Wellness keywords</h5>
                    <p class="card-text">Below are the high frequent Health and wellness keywords</p>
                    <ul>
                        <li><a href="/popular-keywords/acne-keywords">Acne Keywords</a></li>
                        <li><a href="/popular-keywords/diet-keywords">Diet Keywords</a></li>
                        <li><a href="/popular-keywords/energy-keywords">Energy Keywords</a></li>
                        <li><a href="/popular-keywords/fitness-keywords">Fitness Keywords</a></li>
                        <li><a href="/popular-keywords/gardening-keywords">Gardening Keywords</a></li>
                        <li><a href="/popular-keywords/health-care-keywords">Health Care Keywords</a></li>
                        <li><a href="/popular-keywords/health-fitness-keywords">Health Fitness Keywords</a></li>
                        <li><a href="/popular-keywords/nutrition-keywords">Nutrition Keywords</a></li>
                        <li><a href="/popular-keywords/vacations-travel-keywords">Vacations Travel Keywords</a></li>
                        <li><a href="/popular-keywords/weight-loss-keywords">Weight Loss Keywords</a></li>
                    </ul>
                    <a class="btn btn-primary">Health And Wellness Keywords</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Consumer Keywords</h5>
                    <p class="card-text">Below Keywords are high frequent consumer keywords.</p>
                    <ul>
                        <li><a href="/popular-keywords/art-keywords">Art Keywords</a></li>
                        <li><a href="/popular-keywords/automotive-keywords">Automotive Keywords</a></li>
                        <li><a href="/popular-keywords/casino-keywords">Casino Keywords</a></li>
                        <li><a href="/popular-keywords/cell-phone-keywords">Cell Phone Keywords</a></li>
                        <li><a href="/popular-keywords/computer-keywords">Computer Keywords</a></li>
                        <li><a href="/popular-keywords/dating-keywords">Dating Keywords</a></li>
                        <li><a href="/popular-keywords/ecommerce-keywords">Ecommerce Keywords</a></li>
                        <li><a href="/popular-keywords/electronics-keywords">Electronics Keywords</a></li>
                        <li><a href="/popular-keywords/email-keywords">Email Keywords</a></li>
                        <li><a href="/popular-keywords/fashion-keywords">Fashion Keywords</a></li>
                        <li><a href="/popular-keywords/golf-keywords">Golf Keywords</a></li>
                        <li><a href="/popular-keywords/hotel-keywords">Hotel Keywords</a></li>
                        <li><a href="/popular-keywords/internet-keywords">Internet Keywords</a></li>
                        <li><a href="/popular-keywords/jobs-keywords">Jobs Keywords</a></li>
                        <li><a href="/popular-keywords/law-keywords">Law Keywords</a></li>
                        <li><a href="/popular-keywords/mortgage-keywords">Mortgage Keywords</a></li>
                        <li><a href="/popular-keywords/music-keywords">Music Keywords</a></li>
                        <li><a href="/popular-keywords/online-stores-keywords">Online Stores Keywords</a></li>
                        <li><a href="/popular-keywords/pest-control-keywords">Pest Control Keywords</a></li>
                        <li><a href="/popular-keywords/photography-keywords">Photography Keywords</a></li>
                        <li><a href="/popular-keywords/plumbing-keywords">Plumbing Keywords</a></li>
                        <li><a href="/popular-keywords/real-estate-keywords">Real Estate Keywords</a></li>
                        <li><a href="/popular-keywords/restaurants-keywords">Restaurants Keywords</a></li>
                        <li><a href="/popular-keywords/shoes-keywords">Shoes Keywords</a></li>
                        <li><a href="/popular-keywords/travel-keywords">Travel Keywords</a></li>
                        <li><a href="/popular-keywords/wedding-keywords">Wedding Keywords</a></li>
                    </ul>
                    <a class="btn btn-primary">Consumer Keywords</a>
                </div>
            </div>
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Misc Keywords</h5>
                    <p class="card-text">High Frequent Miscellenious Keywords used in 2022.</p>
                    <ul>
                        <li><a href="/popular-keywords/aerospace-keywords">Aerospace Keywords</a></li>
                        <li><a href="/popular-keywords/agriculture-keywords">Agriculture Keywords</a></li>
                        <li><a href="/popular-keywords/best-travel-keywords">Best Travel Keywords</a></li>
                        <li><a href="/popular-keywords/coffee-keywords">Coffee Keywords</a></li>
                        <li><a href="/popular-keywords/dog-training-keywords">Dog Training Keywords</a></li>
                        <li><a href="/popular-keywords/education-keywords">Education Keywords</a></li>
                        <li><a href="/popular-keywords/environmental-keywords">Environmental Keywords</a></li>
                        <li><a href="/popular-keywords/food-and-beverage-keywords">Food &amp; Beverage Keywords</a></li>
                        <li><a href="/popular-keywords/forex-keywords">Forex Keywords</a></li>
                        <li><a href="/popular-keywords/gifts-keywords">Gifts Keywords</a></li>
                        <li><a href="/popular-keywords/home-based-business-keywords">Home Based Business Keywords</a>
                        </li>
                        <li><a href="/popular-keywords/home-improvements-keywords">Home Improvements Keywords</a></li>
                        <li><a href="/popular-keywords/internet-games-keywords">Internet Games Keywords</a></li>
                        <li><a href="/popular-keywords/iphone-keywords">iPhone Keywords</a></li>
                        <li><a href="/popular-keywords/jewlery-keywords">Jewelry Keywords</a></li>
                        <li><a href="/popular-keywords/landscaping-keywords">Landscaping Keywords</a></li>
                        <li><a href="/popular-keywords/legal-keywords">Legal Keywords</a></li>
                        <li><a href="/popular-keywords/luggage-keywords">Luggage Keywords</a></li>
                        <li><a href="/popular-keywords/pharmaceutical-keywords">Pharmaceutical Keywords</a></li>
                        <li><a href="/popular-keywords/poker-keywords">Poker Keywords</a></li>
                        <li><a href="/popular-keywords/retail-keywords">Retail Keywords</a></li>
                        <li><a href="/popular-keywords/school-education-keywords">School Education Keywords</a></li>
                        <li><a href="/popular-keywords/solar-energy-keywords">Solar Energy Keywords</a></li>
                        <li><a href="/popular-keywords/transportation-keywords">Transporation Keywords</a></li>
                        <li><a href="/popular-keywords/women-fashion-keywords">Women Fashion Keywords</a></li>
                        <li><a href="/popular-keywords/work-from-home-keywords">Work From Home Keywords</a></li>
                    </ul>
                    <a class="btn btn-primary">Misc Keywords</a>
                </div>
            </div>

            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Keyword Crawler</h5>
                    <p class="card-text">we have inhouse developed tool to crawl keywords which helps in finding more frequently used words .
                        this would help boost page ranking based on high frequency keywords usage .
                    </p>
                </div>
                <div class="card shadow mt-2">
                    <div class="card-body">
                        <h5 class="card-title">Tools : Auto Blogging Buddy</h5>
                        <p class="card-text">We have developed industry leading software for Auto blogging.the key factor for generating more organic traffic is based on Keywords and content generation.
                            most of the google bots and crwlers scan the keywords frequency in your content and decide page ranking based on that. so you need to have quality content and keywords with high frequency.
                            in the next sesion you will be understandin our another tool <a href='/keyword'>keyword crawler</a> which helps you to get frequency of keywords and generate report
                        </p>
                        <a href='https://www.youtube.com/channel/UC7ibx4i86f6fSBpx0jVrrPw' class="btn btn-primary">Youtube Channel</a>
                    </div>
                    <div class="accordion" id="teknosys-accordion-4873443734">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                        aria-controls="panelsStayOpen-collapseOne">
                                    Teknosys Affiliate Marketing
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                                 aria-labelledby="panelsStayOpen-headingOne">
                                <div class="accordion-body">
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/sq25x9wlNFQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</div>