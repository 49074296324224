<mat-label [style]="dialog">Comments</mat-label><br/>
<mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
</mat-form-field>

<div class="mat-elevation-z8 dk-footer">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="fname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let row"> {{row.fname}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="lname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
      <td mat-cell *matCellDef="let row"> {{row.lname}}% </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>

    <!-- Fruit Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let row"> {{row.description}} </td>
    </ng-container>

    <ng-container matColumnDef="TIME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
      <td mat-cell *matCellDef="let row"> {{row.TIME}} </td>
    </ng-container>
    <ng-container matColumnDef="ip">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> IP </th>
      <td mat-cell *matCellDef="let row"> {{row.ip}} </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>
    <ng-container matColumnDef="display">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Display </th>
      <td mat-cell *matCellDef="let row"> {{row.display}} </td>
    </ng-container>
    
    <ng-container matColumnDef="Block">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> BLOCK </th>
      <td mat-cell *matCellDef="let row"> <input type="button"/> </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of contacts"></mat-paginator>
  
  
  <!-- <button mat-raised-button style="margin: 10px;" (click)="openDialog()" color="primary">ADD</button> -->
  <!-- <button mat-raised-button (click)="getContact()" color="primary">RETRIEVE</button> -->
  <!-- <button mat-raised-button color="warn">Warn</button> -->

 
</div>