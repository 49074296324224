import { Component, OnInit } from '@angular/core';
//import './tek-user-table.component.scss';
@Component({
  selector: 'app-tek-user-table',
  templateUrl: './tek-user-table.component.html',
  styleUrls: ['./tek-user-table.component.scss']
})
export class TekUserTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
