import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { faLock, faIcons } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
//import './tek-register-table.component.scss';
@Component({
  selector: 'app-tek-register-table',
  templateUrl: './tek-register-table.component.html',
  styleUrls: ['./tek-register-table.component.scss']
})
export class TekRegisterTableComponent implements OnInit {
    faLock!: any
    faIcons!: any
    registerForm!: UntypedFormGroup
    constructor(private formBuilder: UntypedFormBuilder, private api: ApiService, private auth: AuthService, private router: Router) {
        this.registerForm = this.formBuilder.group({
            email: new UntypedFormControl(''),
            password: new UntypedFormControl(''),
            repassword: new UntypedFormControl(''),
            username: new UntypedFormControl(''),
            fname: new UntypedFormControl(''),
            lname: new UntypedFormControl(''),
        });
        this.faLock = faLock;
        this.faIcons = faIcons;
    }

    ngOnInit(): void {
        if (this.auth.isLoggedIn()) {
            this.router.navigate(['admin']);
        }
    }
    onSubmit(): void {
        if (this.registerForm.valid) {
            this.api.postRegister(this.registerForm.value).subscribe(
                (result) => {
                    console.log(result);
                    this.router.navigate(['/login']);
                },
                (err: Error) => {
                    alert(err.message);
                }
            );
        }
    }
}
