import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'; 
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ContactDialogComponent } from '../../contact-dialog/contact-dialog.component';
//import './contact.component.scss';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSize = 30;
  pageSizeOptions!: any;
  dialog!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  ngOnInit(): void {
  }

  constructor(private api: ApiService, dialog: MatDialog) {
    // Create 100 users
    this.dialog = dialog;

    // Assign the data to the data source for the table to render

  }

  openDialog() {
    this.dialog.open(ContactDialogComponent, {
      width: "30%"
    });
  }


}