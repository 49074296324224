import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Buffer } from 'buffer/';
import { Cloudinary, CloudinaryVideo } from '@cloudinary/url-gen';
import { Transformation } from '@cloudinary/url-gen';
import { SafeUrlPipe } from './SaferUrlPipe';
//import './home.component.scss';

// Import required actions and qualifiers.
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn";
import { Gravity } from "@cloudinary/url-gen/qualifiers";
import { AutoFocus } from "@cloudinary/url-gen/qualifiers/autoFocus";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    ipAddress!: any;
    prev_url: any;
    a!: string[];
    range = 0;
    vid!: CloudinaryVideo;
    panelOpenState = false;
    constructor(private api: ApiService, private http: HttpClient, private sanitizer: DomSanitizer) {
        this.postIPS();
        this.getIPAddress();
        var URL = window.URL;
        this.api.getArticleByUrl("lineage").subscribe({
            next: (res) => {
                //                console.log(Uint8Array.from(Buffer.from(res[0].content, 'base64')));
                //                console.log(Uint8Array.from(Buffer.from(fileDataProcessed, 'base64')));
                //                const decodedData = new Buffer(res[0].content, 'base64').toString();
                //                console.log(decodedData);
                //                var file = new Blob([res],
                //                    { "type": "binary/octet-stream" });
//                console.log(res.headers.get("content-range")?.split("/"));
//                var p = res.headers.get("content-range")?.split("/")[1];
//                this.range = this.range + 1000000;
//                while (false && this.range <= Number(p).valueOf()) {
//                    console.log(this.range);
//                    this.api.getArticleByUrlA("lineage", this.range).subscribe({
//                        next: (res) => {
                            this.createVideoFromBlob(<Blob>res.body);
//                        }
//                    });
//                    this.range = this.range + 1000000;
//                }
                // this.prev_url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
                //                console.log(res.body);
                //                this.prev_url = "data:binary/octet-stream;base64," + res.body;
                //                console.log(this.prev_url);
                return this.prev_url;
            },
            error: () => {
                alert("error while retrieving the Video data");
            }
        });


    }

    createVideoFromBlob(video: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            //            console.log(reader.result);
            //this.prev_url = reader.result;
            this.prev_url = (URL.createObjectURL(video));
//            this.a.push(this.prev_url);

        }, false);

        if (video) {
            reader.readAsDataURL(video);
        }
    }

    ngOnInit(): void {
        const cld = new Cloudinary({
            cloud: {
                cloudName: 'demo'
            }
        });

        // Use the video with public ID, 'docs/walking_talking'.
        this.vid = cld.video('docs/walking_talking');

        // Apply the transformation.
        this.vid.resize(fill().width(150).height(150)
            .gravity(Gravity.autoGravity().autoFocus(AutoFocus.focusOn(FocusOn.faces())))) // Crop the video, focusing on the faces.
            .roundCorners(byRadius(20));    // Round the corners.
    }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
            localStorage.setItem('ip', this.ipAddress);
        });
        console.log(this.ipAddress);
    }

    postIPS() {
        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.postIPS({
                "asn": "-",
                "city": "-",
                "continent": "-",
                "country": "-",
                "hostname": "-",
                "IP": this.ipAddress,
                "isp": "-",
                "latitude": "-",
                "longitude": "-",
                "postalcode": "-",
                "state": "-",
                "time": "-",
                "VAL": "-"
            }).subscribe({
                next: (res) => {
                    //          alert("Comment added successfully" + res);
                },
                error: () => {
                    alert("error while registering IPS");
                }
            })
        }
    }

}