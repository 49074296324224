import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api.service';
import { Buffer } from 'buffer/';
//import './admin-dashboard.component.scss';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

    prev_url: any;

    constructor(
        private sanitizer: DomSanitizer, private api: ApiService
    ) { }

    onSelectedFile(event: Event) {

        const input = event.target as HTMLInputElement;

        if (!input.files?.length) {
            return;
        }

        const file = input.files[0];
        console.log(file);
        var URL = window.URL;
        this.prev_url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        console.log("POSTING:-->" + file.name);
        file.stream().getReader().read().then((d) => {
            let d1= d.value as Uint8Array;
            console.log(d1);
            var data=Buffer.from(d1).toString('base64');
            this.api.postArticle({ "url": file.name, "content": data, "comments": file.name }).subscribe({
                next: (res) => {
                    alert("Video Uploaded Successfully.")
                },
                error: () => {
                    alert("Error while uploading the Video occurred");
                }
            });
        }).catch(err => {
            console.log(err);
        }).catch(err => {
            console.log(err);
        });

    }

    ngOnInit(): void {
    }

}