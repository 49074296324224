import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {CloudinaryModule} from '@cloudinary/ng';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { TekTableComponent } from './tek-table/tek-table.component';
import { CommonModule } from '@angular/common';
import { MatCommonModule,MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { HttpClientModule } from '@angular/common/http';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import { TekUserTableComponent } from './tek-user-table/tek-user-table.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServicesComponent } from './components/services/services.component';
import { AboutComponent } from './components/about/about/about.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import { TekContactTableComponent } from './tek-contact-table/tek-contact-table.component';
import { TekRegisterTableComponent } from './tek-register-table/tek-register-table.component';
import { TekProductsTableComponent } from './tek-products-table/tek-products-table.component';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { UploadComponent } from './components/upload/upload.component';
import { DndDirective } from './directives/dnd.directive';
import { EmailComponent } from './components/email/email.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { ArticleDialogComponent } from './article-dialog/article-dialog.component';
import { ArticleComponent } from './components/article/article.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { IpdialogComponent } from './ipdialog/ipdialog.component';
import { ProductViewDialogComponent } from './product-view-dialog/product-view-dialog.component';
import { ArticleViewDialogComponent } from './article-view-dialog/article-view-dialog.component';
import { PanchangaComponent } from './panchanga/panchanga.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {SafeUrlPipe} from './components/home/SaferUrlPipe';
import { JQUERY_SERVICE } from './common/jquery.component';

@NgModule({
  declarations: [
    AppComponent,
    TekTableComponent,
    DialogComponent,
    TekUserTableComponent,
    AdminDashboardComponent, 
    HeaderComponent, 
    FooterComponent, 
    HomeComponent, 
    ContactComponent,
    LoginComponent, 
    ServicesComponent, 
    AboutComponent,
    ForgotPasswordComponent,
    TekContactTableComponent,
    TekRegisterTableComponent,
    TekProductsTableComponent,
    ContactDialogComponent,
    PrivacyComponent,
    TermsComponent,
    UploadComponent,
    DndDirective,
    EmailComponent,
    EmailDialogComponent,
    ArticleDialogComponent,
    ArticleComponent,
    SitemapComponent,
    IpdialogComponent,
    ProductViewDialogComponent,
    ArticleViewDialogComponent,
    PanchangaComponent,
    SafeUrlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MatCommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    CloudinaryModule,
    MatExpansionModule
  ],
  providers: [ JQUERY_SERVICE
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
