import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { MatDialogRef } from '@angular/material/dialog'
//import './article-dialog.component.scss';

@Component({
    selector: 'app-article-dialog',
    templateUrl: './article-dialog.component.html',
    styleUrls: ['./article-dialog.component.scss']
})
export class ArticleDialogComponent implements OnInit {


    statusList = ["disable", "enable"];
    articleForm!: UntypedFormGroup

    constructor(private formBuilder: UntypedFormBuilder, private api: ApiService, private dialogRef: MatDialogRef<ArticleDialogComponent>) { }

    ngOnInit(): void {
        this.articleForm = this.formBuilder.group({
            content: ['', Validators.required],
            comments: ['', Validators.required],
            url: ['', Validators.required],
        })
    }

    addArticle() {
        console.log(this.articleForm.value);
        if (this.articleForm.valid) {
            this.api.postArticle(this.articleForm.value)
                .subscribe({
                    next: (res) => {
                        alert("Article Added Successfully.")
                        this.articleForm.reset();
                        this.dialogRef.close();
                    },
                    error: () => {
                        alert("Error while adding the Article occurred");
                    }
                })
        }
    }

}
