import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service'; 
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
//import './tek-contact-table.component.scss';

@Component({
  selector: 'app-tek-contact-table',
  templateUrl: './tek-contact-table.component.html',
  styleUrls: ['./tek-contact-table.component.scss']
})

export class TekContactTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['fname','lname','email','description','TIME','ip','id','display'];
  dataSource!: MatTableDataSource<any>;
  
  

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSize = 30;
  pageSizeOptions!: any;
  dialog!: any;
  ipAddress!:any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private api: ApiService, dialog: MatDialog,private http:HttpClient) {
    // Create 100 users
     this.dialog=dialog;
     this.getContact();
    // Assign the data to the data source for the table to render

  }

  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
    console.log(this.ipAddress);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    this.dialog.open(ContactDialogComponent, {
     width: "30%"
    });
  }

  postContact() {
    if (true) { //form valid need to check
      //this.productForm.value
     return  this.api.postContact({
        "fname": "",
        "lname": "",
        "email": "",
        "description": "North America",
        "TIME": "United States",
        "ip": this.ipAddress,
        "id": "Mountain View",
        "display": "-122.075409"
      }).subscribe({
        next: (res) => {
          alert("Comment added successfully" + res);
        },
        error: () => {
          alert("error while adding the COMMENTS");
        }
      })
    }
  }

  getContact() {
  
    if (true) { //form valid need to check
      //this.productForm.value
     return  this.api.getContact().subscribe({
        next: (res) => {
          // console.log(res);
          this.dataSource=new MatTableDataSource(res);
          this.dataSource.paginator=this.paginator;
          this.dataSource.sort=this.sort;
        },
        error: () => {
          alert("error while retrieving the COMMENTS");
        }
      })
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
}

