import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import './product-view-dialog.component.scss';

@Component({
    selector: 'app-product-view-dialog',
    templateUrl: './product-view-dialog.component.html',
    styleUrls: ['./product-view-dialog.component.scss']
})
export class ProductViewDialogComponent implements OnInit {


    ngOnInit(): void {
    }
    displayedColumns: string[] = ['id', 'category', 'endDate', 'image', 'name', 'reviewCount', 'startDate', 'url', 'approved','block'];
    dataSource!: MatTableDataSource<any>;



    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    pageSize = 30;
    pageSizeOptions!: any;
    dialog!: any;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;


    constructor(private api: ApiService, dialog: MatDialog) {
        // Create 100 users
        this.dialog = dialog;
        this.getProduct();
        // Assign the data to the data source for the table to render

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }



    postProduct() {
        if (true) { //form valid need to check
            //this.productForm.value
            return this.api.postProduct({
                "id": "",
                "category": "",
                "end_date": "",
                "image": "/teknosys.png",
                "name": "Affiliate Product",
                "review_count": "1",
                "start_date": "10/10/2022",
                "url": "/get/affiliate",
                "approved": "approved"
            }).subscribe({
                next: (res) => {
                    alert("Product added successfully" + res);
                },
                error: () => {
                    alert("error while adding the product");
                }
            })
        }
    }

    getProduct() {

        if (true) { //form valid need to check
            //this.productForm.value
            let product = localStorage.getItem('selected-product') || "a";
            console.log("Dialog Product selected:-->" + product);
            return this.api.getProductByName(product).subscribe({
                next: (res) => {
                    // console.log(res);
                    this.dataSource = new MatTableDataSource(res);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                },
                error: () => {
                    alert("error while retrieving the Products");
                }
            })
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }

}
