import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup,UntypedFormBuilder,Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import {MatDialogRef} from '@angular/material/dialog'
//import './dialog.component.scss';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  statusList=["APPROVED","PENDING"];
   productForm!: UntypedFormGroup  

  constructor( private formBuilder : UntypedFormBuilder, private api : ApiService, private dialogRef: MatDialogRef<DialogComponent>) { }

  ngOnInit(): void {
    this.productForm=this.formBuilder.group({
      name: ['',Validators.required],
      category: ['',Validators.required],
      endDate: ['',Validators.required],
      startDate: ['',Validators.required],
      image: ['',Validators.required],
      reviewCount: ['',Validators.required],
      url: ['',Validators.required],
      approved: ['',Validators.required]   
    })
  }

  addProduct(){
    console.log(this.productForm.value);
    if(this.productForm.valid){
      this.api.postProduct(this.productForm.value)
      .subscribe({
        next:(res)=>{
          alert("Product Added Successfully.")
          this.productForm.reset();
          this.dialogRef.close();
        },
        error:()=>{
          alert("Error while adding the product occurred");
        }
      })
    }
  }

}
