<mat-label [style]="dialog"></mat-label>
<mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. affiliate" #input>
</mat-form-field>

<div class="mat-elevation-z8 dk-footer" >
    <table class="dk-footer" mat-table [dataSource]="dataSource" matSort *ngFor="let i of slideHtml; let $index=index;" >

        <!-- ID Column -->
        <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Content </th>
            <td mat-cell *matCellDef="let row" innerHTML="{{row.content}}" > {{row.content}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comments </th>
            <td mat-cell *matCellDef="let row"> {{row.comments}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> URL </th>
            <td mat-cell *matCellDef="let row"> <img src={{row.url}} /></td>
        </ng-container>
        <ng-container matColumnDef="block">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
            <td mat-cell *matCellDef="let row"> <button mat-raised-button  color="primary">Delete</button> </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of Articles"></mat-paginator>



    <!-- <button mat-raised-button color="warn">Warn</button> -->


</div>