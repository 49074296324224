import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service'; 
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EmailDialogComponent } from '../../email-dialog/email-dialog.component';
//import './email.component.scss';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements AfterViewInit {

 displayedColumns: string[] = ['sno','email','disable'];
  dataSource!: MatTableDataSource<any>;
  
  

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSize = 30;
  pageSizeOptions!: any;
  dialog!: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private api: ApiService, dialog: MatDialog) {
    // Create 100 users
     this.dialog=dialog;

    // Assign the data to the data source for the table to render

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    this.dialog.open(EmailDialogComponent, {
     width: "30%"
    });
  }

  postEmail() {
    if (true) { //form valid need to check
      //this.productForm.value
     return  this.api.postEmail({
        "email": "",
        "disable": "",
        "end_date": "",
      }).subscribe({
        next: (res) => {
          alert("Product added successfully" + res);
        },
        error: () => {
          alert("error while adding the product");
        }
      })
    }
  }
  getToken(): string  {
        return localStorage.getItem('token')||'abc@gmail.com';
    }

  getAllEmail() {
  
    if (true) { //form valid need to check
      //this.productForm.value
      
     return  this.api.getAllEmail().subscribe({
        next: (res) => {
          // console.log(res);
          this.dataSource=new MatTableDataSource(res.emails);
          this.dataSource.paginator=this.paginator;
          this.dataSource.sort=this.sort;
        },
        error: () => {
          alert("error while retrieving the Emails");
        }
      })
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

}
