<mat-label [style]="dialog"></mat-label>
<mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. affiliate" #input>
</mat-form-field>

<div class="mat-elevation-z8 dk-footer" >
  <table class="dk-footer" mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> S.no </th>
      <td mat-cell *matCellDef="let row"> {{row.sno}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="disable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Disable </th>
      <td mat-cell *matCellDef="let row"> <img src={{row.disable}} /></td>
    </ng-container>

    


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of contacts"></mat-paginator>
  
  
  <button mat-raised-button style="margin: 10px;" (click)="openDialog()" color="primary">ADD</button>
  <button mat-raised-button (click)="getAllEmail()" color="primary">RETRIEVE</button>
  <!-- <button mat-raised-button color="warn">Warn</button> -->

 
</div>