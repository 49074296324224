import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    ipAddress!: any;

    getToken(): string {
        return localStorage.getItem('token') || 'abc@gmail.com';
    }

    fileHeaders = new HttpHeaders()
        .append("Accept", "multipart/form-data",)
        .append("api-key", "9751acb06414d61adcc357a05439a4506b9e1e45744bff8d6b0a74a0625aa376");
    headers = new HttpHeaders()
        .append(
            'Content-Type', 'application/json',
        )
        .append("api-key", "9751acb06414d61adcc357a05439a4506b9e1e45744bff8d6b0a74a0625aa376")


    params = new HttpParams()
        .append('email', this.getToken())


    constructor(private http: HttpClient) { this.getIPAddress(); this.getIP(); }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
            return this.ipAddress;
        });
        console.log(this.ipAddress);
        return this.ipAddress;
    }
    getIP() {
        this.ipAddress = localStorage.getItem('ip');
    }

    postIPS(data: any) {
        console.log("posting data" + data);
        data.ip = this.ipAddress;
        return this.http.post<any>("/api/ip/insert", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }


    postContact(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/contact/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    postProduct(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/products/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    postArticle(data: any) {
        console.log("posting data" + data);
        return this.http.post<any>("/api/article/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    postEmail(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/email/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    postFile(file: any) {
        // console.log("posting data"+data);

        // Create form data
        const formData = new FormData();

        // Store form name as "file" with file data
        formData.append("file", file, file.name);
        //      formData.append('file', new Blob([JSON.stringify({"portfolio":"seo","changedBy":"seo","ip":"test","version":12})], {type: 'application/json'}));


        // Make http post request over api
        // with formData as req
        return this.http.post<any>("/api/upload/image/import", formData, {
            headers: this.fileHeaders,
            params: this.params.append('ip', this.ipAddress)
                .append("portfolio", "seo")
                .append("changedBy", "seo")
                .append("version", 12)
        });
    }
    postRegister(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/users/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    postPanchanga(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/panchanga/create", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    getIPInfo(ip: string) {
        // console.log("getting data");
        return this.http.get<any>("/api/ip/get/IP/" + ip, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    getIPS() {
        // console.log("getting data");
        return this.http.get<any>("/api/ip/get/IP1/1", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    getVideoData(): string {
        // console.log("getting data");
        this.http.get<any>("/dash/output022.mp4", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        }).subscribe({
            next: (res) => {
                console.log(res.body);
                return res.body;
            },
            error: () => {
                alert("error while retrieving the Video data");
            }
        });
        return "";
    }
    getProduct() {
        // console.log("getting data");
        return this.http.get<any>("/api/products/get/a", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    getProductByName(name: string) {
        // console.log("getting data");
        return this.http.get<any>("/api/products/get/" + name, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    getArticle() {
        // console.log("getting data");
        return this.http.get<any>("/api/article/get/a", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    getArticleByUrl(url: string): Observable<HttpResponse<Blob>> {
        let urls = url.split("=");
        console.log("getting Article by URL " + urls);
        if (urls.length >= 2) {
            url = urls[1];
        } else {
            url = urls[0];
        }
        return this.http.request('GET', "/api/article/getblob/" + url, {
            responseType: 'blob' ,
            observe: 'response' ,
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    
    getArticleByUrlA(url: string, range1: number): Observable<HttpResponse<Blob>> {
        let urls = url.split("=");
        console.log("getting Article by URL " + urls);
        if (urls.length >= 2) {
            url = urls[1];
        } else {
            url = urls[0];
        }
        var range2=range1+1000000;
        return this.http.request('GET', "/api/article/getbloba/" + url, {
            responseType: 'blob' ,
            observe: 'response' ,
            headers: this.headers.append("Accept-Range",range1+"-"+range2),
            params: this.params.append('ip', this.ipAddress)
        });
    }
    getEmail(email: string) {
        // console.log("getting data");
        return this.http.get<any>("/api/email/get/" + email, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }

    getAllEmail() {
        // console.log("getting data");
        return this.http.get<any>("/api/email/get/all", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
    getContact() {
        // console.log("getting data");
        return this.http.get<any>("/api/contact/get/all", {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        });
    }
}
