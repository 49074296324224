<div class="container pt-5 mt-5">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h2>
                    Privacy Policy</h2>
                <div class="error-details">
                    <div class="panel">

                        <h2 style="text-align: left;">Privacy Policy of Teknosys!&nbsp;
                        </h2>
                        <div>Who we are Our website address is <a href="https://www.teknosys.in/">https://www.teknosys.in/</a>
                            What personal data we collect and why we collect it Comments
                            When visitors leave comments on the site we collect the data
                            shown in the comments form, and also the visitor's IP
                            address and browser user agent string to help spam
                            detection. An anonymized string created from your email
                            address (also called a hash) may be provided to the Gravatar
                            service to see if you are using it. The Gravatar service
                            privacy policy is available here: <a href="https://www.teknosys.in/privacy">https://www.teknosys.in/privacy</a>.&nbsp;
                        </div>
                        <div><br></div>
                        <div>After approval of your comment, your profile picture is
                            visible to the public in the context of your comment. Media:
                            If you upload images to the website, you should avoid
                            uploading images with embedded location data (EXIF GPS)
                            included. Visitors to the website can download and extract
                            any location data from images on the website. Contact Forms
                            &amp; Cookies</div>
                        <div><br></div>
                        <div>&nbsp;If you leave a comment or fill up a form on our site,
                            you may opt-in to save your name, email address and website
                            in cookies. These are for your convenience so that you do
                            not have to fill in your details again when you leave
                            another comment. These cookies will last for one year. If
                            you have an account and you log in to this site, we will set
                            a temporary cookie to determine if your browser accepts
                            cookies. This cookie contains no personal data and is
                            discarded when you close your browser. When you log in, we
                            will also set up several cookies to save your login
                            information and your screen display choices. Login cookies
                            last for two days, and screen options cookies last for a
                            year.&nbsp;</div>
                        <div><br></div>
                        <div>
                            <blockquote>If you select ?Remember Me?, your login will
                                persist for two weeks. If you log out of your account,
                                the login cookies will be removed. If you edit or
                                publish an article, an additional cookie will be saved
                                in your browser. This cookie includes no personal data
                                and simply indicates the post ID of the article you
                                just edited. It expires after 1 day. Embedded Content
                                From Other Websites: Articles on this site may include
                                embedded content (e.g. videos, images, articles,
                                etc.).&nbsp;</blockquote>
                        </div>
                        <div><br></div>
                        <div>Embedded content from other websites behaves in the exact
                            same way as if the visitor has visited the other website.
                            These websites may collect data about you, use cookies,
                            embed additional third-party tracking, and monitor your
                            interaction with that embedded content, including tracking
                            your interaction with the embedded content if you have an
                            account and are logged in to that website. Analytics- How
                            long we retain your data: If you leave a comment, the
                            comment and its metadata are retained indefinitely. This is
                            so we can recognize and approve any follow-up comments
                            automatically instead of holding them in a moderation queue.
                            For users that register on our website (if any),</div>
                        <div><br></div>
                        <div>&nbsp;we also store the personal information they provide in
                            their user profile. All users can see, edit, or delete their
                            personal information at any time (except they cannot change
                            their username). Website administrators can also see and
                            edit that information. What rights you have over your data:
                            If you have an account on this site or have left comments or
                            fill up form, you can request to receive an exported file of
                            the personal data we hold about you, including any data you
                            have provided to us. You can also request that we erase any
                            personal data we hold about you. This does not include any
                            data we are obliged to keep for administrative, legal, or
                            security purposes. Where we send your data: Visitor comments
                            may be checked through an automated spam detection
                            service.&nbsp;</div>
                        <div><br></div>
                        <div>
                            <blockquote>Compliance With Children's Online Privacy
                                Protection Act: Protecting the privacy of the very
                                young is especially important. For that reason, we
                                never collect or maintain information at our Site from
                                those we actually know are under 13, and no part of
                                our website is structured to attract anyone under
                                13.&nbsp;</blockquote>
                        </div>
                        <div><br></div>
                        <div>Your Acceptance Of These Terms: By using this Site, you
                            signify your acceptance of this policy and terms of service.
                            If you do not agree to this policy, please do not use our
                            Site. Your continued use of the Site following the posting
                            of changes to this policy will be deemed your acceptance of
                            those changes. Contact information: If you face any problem
                            with the contents or the site, feel free to write us at <a href="mailto:admin@teknosys.in">admin@teknosys.in</a>
                        </div>
                    </div>
                </div>
                <div class="error-actions">
                    <a href="/#/home" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        Take Me Home </a><a href="/#/contact" class="btn btn-default btn-lg"><span class="glyphicon glyphicon-envelope"></span> Contact Support </a>
                </div>
            </div>
        </div>
    </div>
</div>