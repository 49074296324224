<div class="container pt-5 pb-5">
    <div class="col-12">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="card dk-footer shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Affilioate Marketing Videos</h5>
                    <p class="card-text">All affiliate marketing vieods located here. You will be learning how to make
                        money online fun stuff.</p>
                    <a class="btn btn-primary" href="https://youtube.com/@teknosys?si=1QtG3aFmrU22uR8i">Youtube Channel</a>
                </div>
            </div>
            <div class="card dk-footer shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Tools</h5>
                    we have develped awesome tools which help you to generate more traffic to your website.
                    <ul>
                        <li><a href='/autoblogger'>Autoblog Buddy</a></li>
                        <li><a href='/keyword'>Keyword Crawler</a></li>
                        <li><a href='/dofollow'>Dofollow Links Generator</a></li>
                        <li><a href='/mailbooster'>Mass Email Marketing tool</a></li>
                    </ul>
                </div>
            </div>
            <div class="card dk-footer shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Make Money Online.How it works?</h5>
                    <p class="card-text">
                        You might have wondered how money making can be done online . it is all easy . you no need to be
                        tech geek. all you need rich content . dont worry even if you dont have that .
                        you can go through my youtube channel and learn how you can get content that is not copyrighted
                        . you will be finding multiple videos
                        whcih help you to get content and upload it on youtube generate revenue. for more info you can
                        click on youtube channel above mentioned.
                        are you tired of working 9-5 and not taking care of your family , not spending much time with
                        them. we have solution for that all you need to go through the
                        channel and understand concepts . below are topics which we have briefly explained .
                    </p>
                    <ul>
                        <li><a href='https://youtu.be/esJkKXbgfTI'>Adsense Make money online</a></li>
                        <li><a href='https://youtu.be/8vFwg9ABhMI'>Upload rich content products and make money through Affiliate Marketing</a></li>
                        <li><a href='https://youtu.be/vS0qjIdubvc'>Good Adwords (Google Ads) in nutshell</a></li>
                        <li><a href='https://youtu.be/sq25x9wlNFQ'>insta keywords search tool  </a></li>
                        <li><a href='https://youtu.be/BbiCDxcSRQo'>Professional website design   </a></li>
                        <li><a href='https://youtu.be/QKuzD8R25OA'>Mass Email Marketing  </a></li>
                        <li><a href='https://youtu.be/2oaEAyRvyCA'>Secret Email System  </a></li>
                        <li><a href='https://youtu.be/lm4sJrG6j0k'>Promotions Ever Green  </a></li>
                        <li><a href='https://youtu.be/OLHdqlhduwM'>Traffic Generate Tool   </a></li>
                        <li><a href='https://youtu.be/nONAaNhKpgc'>Millionaire Mindset   </a></li>
                        <li><a href='https://youtu.be/65e-PMXcwlc'>How to remix your business   </a></li>
                        <li><a href='https://youtu.be/8vFwg9ABhMI'>Create Rich video content </a></li>
                        <li><a href='https://youtu.be/Tp8awQ1y3gc'>Our old website with new look  </a></li>
                        <li><a href='https://youtu.be/imAYJI15OEk'>Adsene Revenue report  </a></li>
                        <li><a href='https://youtube.com/shorts/CDajaR9QmPA?feature=share'>Screen Recording tool  </a></li>
                        <li><a href='https://youtu.be/ljax4ySKo-M'>Generate Money Through - Crypto Commission   </a> </li>
                        <li><a href='https://youtu.be/-CE8w8tnYL4'>SEO Syndication</a></li>
                    </ul>
                </div>
            </div>
            <div class="card dk-footer  shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Register</h5>
                    <p class="card-text">if you register on this website with valid email , you will unlock awesome
                        features.</p>
                    <a href="/#/register" class="btn btn-primary">Register</a>

                    <!-- In your view add the advanced-image component and pass your Cloudinary image.-->
                </div>
            </div>

            <div class="card dk-footer  shadow mt-2">
                <div class="card-body">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Adsense Money making techniques
                                </mat-panel-title>
                                <mat-panel-description>
                                    You will be learning how to make passive income.
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p>Explore more videos.</p>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true"
                                             (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Money make techniques
                                </mat-panel-title>
                                <mat-panel-description>
                                    Make Money {{panelOpenState ? 'open' : 'closed'}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p>
                            <video class="vjs-tech video-js vjs-big-play-centered ------packages-feed-ui-src-components-PhotosAndMapImage-PhotoGrid-VideoItem-VideoItem__video--So1Ik ------packages-shared-ui-src-Video-Video__video-player--HRqEg vjs-fluid vjs_video_3-dimensions vjs-controls-disabled vjs-workinghover vjs-v7 vjs-layout-x-small vjs-has-started vjs-user-inactive vjs-playing" autoplay="true"  *ngIf="prev_url"   [src]="prev_url | safeResourceUrl"   style="width:720px; height:300px;" controlslist="nodownload"  disablepictureinpicture=""  controls></video>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>


                </div>
            </div>
        </div>
        <app-tek-table></app-tek-table>
    </div>
