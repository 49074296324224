
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>dialpad</mat-icon>
      <span>Redial</span>
    </button>
    <button mat-menu-item disabled>
      <mat-icon>voicemail</mat-icon>
      <span>Check voice mail</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
  </mat-menu>
  
<div class="container dk-footer">
    
    <div style="margin-top: 10px;">
        <router-outlet></router-outlet>
    </div>
</div>
