
<div class="container pt-5 pb-5">
    <div class="col-12">
        <div class="d-flex flex-wrap justify-content-between">
            <div class="card shadow mt-2">
                <div class="card-body">
                    <h5 class="card-title">Contact US</h5>
                    <p class="card-text">You can contact us through this form.</p>
                    <a class="btn btn-primary" (click)="openDialog()">contact us</a>
                </div>
            </div>
            
        </div>
    </div>
    <app-tek-contact-table></app-tek-contact-table>
</div>