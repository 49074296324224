import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { faLock, faIcons } from '@fortawesome/free-solid-svg-icons';
//import './login.component.scss';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    faLock!: any
    faIcons!: any
    loginForm!: UntypedFormGroup
    constructor(private formBuilder: UntypedFormBuilder, private auth: AuthService, private router: Router) {
        this.loginForm = this.formBuilder.group({
            email: new UntypedFormControl(''),
            password: new UntypedFormControl(''),
        });
        this.faLock = faLock;
        this.faIcons = faIcons;
    }

    ngOnInit(): void {
        if (this.auth.isLoggedIn()) {
            this.router.navigate(['admin']);
        }
    }
    onSubmit(): void {
        if (this.loginForm.valid) {
            this.auth.login(this.loginForm.value).subscribe(
                (result) => {
                    console.log(result);
                    this.router.navigate(['/home']);
                },
                (err: Error) => {
                    console.log(err.message);
                    alert(err.message);
                }
            );
        }
    }
}