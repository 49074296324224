import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    ipAddress!: any;

    headers = new HttpHeaders()
        .append(
            'Content-Type', 'application/json',
        )
        .append("api-key", "9751acb06414d61adcc357a05439a4506b9e1e45744bff8d6b0a74a0625aa376")


    params = new HttpParams()
        .append('email', 'ram@teknosys.in')
    constructor(private router: Router, private http: HttpClient) { this.getIP(); }

    getIPAddress() {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
            return this.ipAddress;
        });
        console.log(this.ipAddress);
        return this.ipAddress;
    }


    setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    getToken(): string | null {
        return localStorage.getItem('token');
    }
    
    

    isLoggedIn() {
//        console.log("TOKEN:-->" + this.getToken() + ":" + (this.getToken() !== null));
        return this.getToken() !== null;
    }

    logout() {
        console.log(localStorage.getItem('token'))
        localStorage.clear();
        localStorage.removeItem('token');
        this.router.navigate(['login']);
    }

    getUser(data: any) {
        // console.log("posting data"+data);
        this.http.get<any>("/api/users/get/" + data.email + "/GUEST", {
            headers: this.headers,
            params: this.params.append('ip', this.getIPAddress())
        }).subscribe((res: any) => {
            console.log(res);
            return true;
        });
        return false;
    }

    postLogin(data: any) {
        // console.log("posting data"+data);
        return this.http.post<any>("/api/users/login", data, {
            headers: this.headers,
            params: this.params.append('ip', this.ipAddress)
        })
    }
    
    getIP()  {
        this.ipAddress= localStorage.getItem('ip');
    }

    login({ email, password }: any): Observable<any> {

        this.postLogin({ 'email': email, 'password': password }).subscribe({
            next: (res) => {
//                alert("Logged in " + res.body);
                this.setToken(email);
                return of({ name: email, email: email });
            },
            error: () => {
               return throwError(new Error('Failed to login '));
            }
        })

        return of({ name: email, email: email });


  }
}